import React, { Component } from "react"
import styled from "styled-components"
import { Modal } from "antd"

const SeeHowButton = styled.button`
  position: fixed;
  bottom: 0;
  right: 0;
  display: inline-flex;
  margin: 0 3em 25em 0;
  z-index: 1000;
  animation-delay: 3s;
  animation: fadein 3s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const Header = styled.h2`
  margin-top: 0px;
  text-align: center;
`

const Paragraph = styled.p`
  text-align: center;
`

const FormStyles = styled.div`
  text-align: center;
`

export default class SeeHowItWorks extends Component {
  state = {
    visible: false,
    name: "",
    email: "",
    submitted: false,
  }
  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = e => {
    this.setState({
      visible: false,
    })
  }

  handleCancel = e => {
    this.setState({
      visible: false,
    })
  }

  handleChange = e => {
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change)
  }
  onSubmit = event => {
    event.preventDefault()
    this.setState({ submitted: true })
    let ifrm = document.querySelector(".iframehack");
    let win;
    try {
      win = ifrm.contentWindow;
    } catch(e) {
      win = ifrm.contentWindow;
    }
    const userEmail = this.state.email
    const userName = this.state.name
    var obj = {
      emailForSignIn: userEmail,
      usernameForSignIn: userName,
    }
    const ret = win.postMessage(
      JSON.stringify({ key: "storage", data: obj }),
      "*"
    )
    this.props.firebase
      .doSignInWithLink(userEmail, "introduction")
      .then(authUser => {
        // console.log(authUser)
      })
  }
  render() {
    const { email, submitted, name } = this.state
    const isInvalid = email === "" && name === ""
    return (
      <>
        <SeeHowButton
          className="button button-primary button-shadow"
          onClick={this.showModal}
        >
          Take a Quick Test Drive
        </SeeHowButton>
        <Modal
          title="Take a Quick Test Drive"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          width="50%"
        >
          <Header>Enter Your Name and Email:</Header>
          <Paragraph>And we'll send you a link to demo LivingWisely.</Paragraph>
          <FormStyles className="fancy-form">
            {submitted ? (
              <p>Please check your email for a link to login to your demo.</p>
            ) : (
              <form onSubmit={event => this.onSubmit(event)}>
                <div className="group">
                  <label>
                    <span>Name</span>
                    <input
                      value={name}
                      onChange={event => this.handleChange(event)}
                      type="text"
                      name="name"
                      className="field username"
                      placeholder="Jane Doe"
                      required
                    />
                  </label>
                  <label>
                    <span>Email</span>
                    <input
                      value={email}
                      onChange={event => this.handleChange(event)}
                      type="email"
                      name="email"
                      className="field useremail"
                      placeholder="you@email.com"
                      required
                    />
                  </label>
                </div>
                <button
                  type="primary"
                  block={true}
                  disabled={isInvalid}
                  htmlType="submit"
                  style={{ "margin-top": "1rem" }}
                  className="button button-primary button-shadow"
                >
                  Submit
                </button>
              </form>
            )}
          </FormStyles>

          <iframe
            src="https://introduction.livingwisely.org/"
            class="iframehack"
            width="1"
            height="1"
            style={{ visibility: "hidden" }}
          ></iframe>
        </Modal>
      </>
    )
  }
}
