import React, { useEffect, useState } from "react"
import Header from "./Header"
import B2cHeader from "./b2c/Header"
import Footer from "./Footer"
import SeeHowItWorks from "./b2c/SeeHowItWorks"
import Helmet from "react-helmet"
import { Link } from "gatsby"
import layoutStyles from "../styles/components/layout.module.scss"
import { globalHistory } from "@reach/router"

import getFirebase, { FirebaseContext } from "./Firebase"

export default function Layout(props) {
  const [firebase, setFirebase] = useState(null)
  useEffect(() => {
    const app = import("firebase/app")
    const auth = import("firebase/auth")

    Promise.all([app, auth]).then(values => {
      const firebase = getFirebase(values[0])

      setFirebase(firebase)
    })

    const sr = require("scrollreveal").default()
    sr.reveal(".hero-shapes", {
      duration: 1000,
      distance: "40px",
      easing: "cubic-bezier(0.5, -0.01, 0, 1.005)",
      origin: "right",
    })
    sr.reveal(".header-sun", {
      scale: 0.9,
      easing: "cubic-bezier(0.5, -0.01, 0, 1.005)",
      viewFactor: 0.5,
    })
    sr.reveal(".clients li", {
      duration: 500,
      rotate: {
        y: 50,
      },
      easing: "cubic-bezier(0.215, 0.61, 0.355, 1)",
      interval: 150,
    })
    sr.reveal(".hero-media", {
      delay: 200,
      scale: 0.9,
      easing: "cubic-bezier(0.5, -0.01, 0, 1.005)",
      viewFactor: 0.5,
    })
    sr.reveal(".hero-copy", {
      delay: 200,
      scale: 0.9,
      easing: "cubic-bezier(0.5, -0.01, 0, 1.005)",
      viewFactor: 0.5,
    })
    sr.reveal(".hero-text", {
      delay: 200,
      scale: 0.9,
      easing: "cubic-bezier(0.5, -0.01, 0, 1.005)",
      viewFactor: 0.5,
    })
    sr.reveal(".hero-paragraph", {
      delay: 200,
      scale: 0.9,
      easing: "cubic-bezier(0.5, -0.01, 0, 1.005)",
      viewFactor: 0.5,
    })
    sr.reveal(".hero-header", {
      delay: 50,
      scale: 1,
      easing: "cubic-bezier(0.5, -0.01, 0, 1.005)",
      viewFactor: 0.5,
    })
    // sr.reveal(".feature-extended-body", {
    //   duration: 600,
    //   scale: 0.9,
    //   easing: "cubic-bezier(0.5, -0.01, 0, 1.005)",
    //   viewFactor: 0.5,
    // })
    // sr.reveal(".feature-extended-image", {
    //   duration: 600,
    //   scale: 0.9,
    //   easing: "cubic-bezier(0.5, -0.01, 0, 1.005)",
    //   viewFactor: 0.5,
    // })
    sr.reveal(".testimonial, .pricing-table", {
      duration: 600,
      distance: "40px",
      easing: "cubic-bezier(0.5, -0.01, 0, 1.005)",
      interval: 100,
      origin: "bottom",
      viewFactor: 0.5,
    })
    sr.reveal(
      ".feature, .tabs-links li, .testimonial, .pricing-table, .pricing-faqs, .cta-inner",
      {
        duration: 600,
        distance: "40px",
        easing: "cubic-bezier(0.215, 0.61, 0.355, 1)",
        interval: 100,
        origin: "bottom",
        viewFactor: 0.2,
      }
    )
  }, [])

  const currentPath = globalHistory.location.pathname

  let metaTitle

  if (currentPath.startsWith("/organizations"))
    metaTitle = "Maximize your impact, transform lives"
  else if (currentPath.startsWith("/individuals"))
    metaTitle = "Live your best life with insight and intention"
  else metaTitle = "Your best life on purpose"

  return (
    <FirebaseContext.Provider value={firebase}>
      <section
        className={`${layoutStyles.layout} ${props.page === "info" &&
          layoutStyles.info_page} ${props.darkMode && "dark-mode"}`}
        style={{
          backgroundColor: props.bgColor,
        }}
      >
        <Helmet>
          <html lang="en" />
          <meta charSet="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>LivingWisely - {metaTitle}</title>

          <link
            href="https://fonts.googleapis.com/css?family=Hind+Vadodara:400,600,700|Frank+Ruhl+Libre:300,400,600,700"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="https://unpkg.com/tippy.js@4/themes/light.css"
          />
          <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
            integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf"
            crossOrigin="anonymous"
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/tingle/0.14.0/tingle.min.css"
          />

          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-139471248-1"
          ></script>
        </Helmet>
        {props.b2c ? (
          <B2cHeader page={props.page} {...props} />
        ) : (
          <Header page={props.page} {...props} />
        )}
        <div
          className={
            props.noContainer ? "section-inner" : "container section-inner"
          }
        >
          {props.children}
        </div>
        {props.showCta === false ? null : (
          <p className="hero-cta hero-copy hero-text">
            {props.b2c ? (
              <></>
            ) : (
              <Link
                className="button button-primary cta-shadow"
                data-scroll=""
                to={"organizations/start"}
                style={{
                  marginBottom: "20px"
                }}
              >
                Are You Ready? Let's Get Started!
              </Link>
            )}
          </p>
        )}
        {props.showFooter === false ? null : <Footer page={props.page} />}
        {props.showCta === false
          ? null
          : null /*<SeeHowItWorks firebase={firebase} />*/}
      </section>
    </FirebaseContext.Provider>
  )
}
