import React from "react"
import { Link } from "gatsby"
import Tippy from "@tippy.js/react"
import ContentHeader from "./ContentHeader"
import styled from 'styled-components';

const Nav = styled.ul`
  li {
    a {
      i {
        margin-right: 6px;
      }
    }
  }
`;

export const tippyProps = {
  theme: "light",
  trigger: "click",
  maxWidth: 500,
  content: <div style={{ width: "100%", textAlign: "left" }}>
    <span>
      <div className="flex">
        <a><div className="avatar" style={{ backgroundImage: "url('/uploads/estate-fin-planning.jpg')" }}/></a>
        <div>
          <h4><Link to="organizations/orgs/donors">Donors & Clients</Link></h4>
          <div style={{color: "#26323d"}}>Encourage Insightful Planning and Decision
            Making</div>
        </div>
      </div>
    </span>

    <span>
      <div className="flex">
        <a><div className="avatar" style={{ backgroundImage: "url('/uploads/medorg.jpg')" }}/></a>
        <div>
          <h4><Link to="organizations/orgs/patients">Patients, Families & Caregivers</Link></h4>
          <div style={{color: "#26323d"}}>Improve Quality of Life, Healing and Resilience
          </div>
        </div>
      </div>
    </span>

    <span>
      <div className="flex">
        <a><div className="avatar" style={{ backgroundImage: "url('/uploads/commorg.jpg')" }}/></a>
        <div>
          <h4><Link to="organizations/orgs/employees">Employees, Seniors & Members</Link></h4>
          <div style={{color: "#26323d"}}>Inspire Engagement and Purposeful Living</div>
        </div>
      </div>
    </span>


  </div>,
  arrow: true,
  placement: "bottom-start",
  interactive: true,
  arrowType: "round", // or 'sharp' (default)
}
const Header = ({ topTitle, topSubtitle, title, subtitle, showHeader, darkMode }) => (
  <div>
    <header className="site-header">
      <div className="header-sun " data-sr-id="0">
      </div>
    </header>
    <div className="container load-hidden">
      <div className="site-header-inner-single">
        <div className="brand header-brand sr">
          <h1 className="m-0">
            <a>
              <div className="hero-logo">
                <div
                  className="hero-media "
                  style={{
                    perspective: "800px",
                  }}
                >
                  <img
                    className="logo"
                    src={darkMode ? "/uploads/LivingWisely_Black_Tagline_Final.png" : "/uploads/LivingWisely_Final.jpg"}
                  />
                </div>
              </div>
            </a>
          </h1>
        </div>
      </div>
    </div>
    {topTitle && <section className="hero">
      <div className="container">
        <div className="hero-inner">
          <div className="hero-copy">
            <h1 className="hero-title mt-0" id="no-justify">
              {topTitle}
            </h1>
            <p className="hero-paragraph text-lg">
              {topSubtitle}
            </p>
          </div>
        </div>
      </div>
    </section>}
    {showHeader === false ? null :
      <section className="clients section">
        <div className="container">
          <div className="clients-inner">
            <div className="container">
              <Nav className="list-reset mb-0">
                <li className>
                  <Link
                    className="button button-primary button-block cta cta-shadow"
                    to="/organizations"
                  >
                    <i className="fa fa-home"/>
                    Home
                  </Link>
                </li>
                <li className>
                  <Tippy {...tippyProps}>
                    <a
                      id="nav-org"
                      className="button button-primary button-block cta cta-shadow"
                    >
                      <i className="fa fa-hands-helping"/>
                      Organizations We Serve
                    </a>
                  </Tippy>
                </li>
                <li className>
                  <Link
                    className="button button-primary button-block cta cta-shadow"
                    to="organizations/howlwworks"
                  >
                    <i className="fa fa-lightbulb"/>
                    How LivingWisely Works
                  </Link>
                </li>
                <li className>
                  <Link
                    className="button button-primary button-block cta cta-shadow"
                    to="organizations/faq"
                  >
                    <i className="fa fa-question-circle"/>
                    FAQs
                  </Link>
                </li>
                <li className>
                  <Link
                    className="button button-primary button-block cta cta-shadow"
                    to="organizations/team"
                  >
                    <i className="fa fa-users"/>
                    Our Team
                  </Link>
                </li>
                <li className>
                  <Link
                    className="button button-primary button-block cta cta-shadow"
                    to="organizations/start"
                  >
                    <i className="fa fa-bullseye"/>
                    Let's Get Started
                  </Link>
                </li>
              </Nav>
            </div>
          </div>
        </div>
      </section>
    }
    {title && <ContentHeader title={title} subtitle={subtitle}/>}
  </div>
)
export default Header
