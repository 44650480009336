import React from "react"

const Footer = () => (
  <footer className="site-footer">
    <div className="container">
      <div className="hero-copy">
        <a onClick={() => window.print()} className="hero-cta">
          <img
            className="hero-print print"
            src="/uploads/icons8-print-100.png"
          />
        </a>
      </div>
      <div className="site-footer-inner">
        <div className="footer-links list-reset">
          <div>
            <img
              className="celebrations-of-life"
              src="/uploads/whiteCelebrationslogoregisteredtrademark.png"
            />
            <div className="footer-justify">
              <br />
              215 10th Avenue South, Suite 812
              <br />
              Minneapolis, MN 55415
              <br />
              651.600.6412 &middot; 651.600.6413
              <br />
              <div>
                <a href="mailto:contact@livingwisely.org">
                  contact@livingwisely.org
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          © 2019 - All intellectual property and content included in this
          website,
          <br />
          along with the LivingWisely™ name/logo, the LegacyJourney® name,
          <br />
          the MyBPFF® name/logo, and the Celebrations of Life® name/logo are
          <br />
          exclusive properties of Celebrations of Life Services Inc. <br />
          <br />
          LivingWisely™ is a division of Celebrations of Life Services Inc.
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
