const config = {
  apiKey: "AIzaSyDEDYRQDO1nI4NMa_yrIFQrpZu91j-l1A4",
  authDomain: "livingwiselyfirebaserealtime.firebaseapp.com",
  databaseURL: "https://livingwiselyfirebaserealtime.firebaseio.com",
  messagingSenderId: "667065077195",
  projectId: "livingwiselyfirebaserealtime",
  storageBucket: "livingwiselyfirebaserealtime.appspot.com",
}

class Firebase {
  constructor(app) {
    app.initializeApp(config)
    this.auth = app.auth()
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  doSignInWithLink = (email, org) => {
    var actionCodeSettings = {
      // The URL to redirect to for sign-in completion. This is also the deep
      // link for mobile redirects. The domain (www.example.com) for this URL
      // must be whitelisted in the Firebase Console.
      url: `https://${org}.livingwisely.org/?email=${email.toLowerCase()}`,
      handleCodeInApp: true,
    }
    return this.auth.sendSignInLinkToEmail(email, actionCodeSettings)
  }
}

let firebase

function getFirebase(app, auth, database) {
  if (!firebase) {
    firebase = new Firebase(app, auth, database)
  }

  return firebase
}

export default getFirebase
