import React from "react"
import { Link } from "gatsby"
import ContentHeader from "../ContentHeader"
import styled from "styled-components"

const Nav = styled.ul`
  li {
    a {
      i {
        margin-right: 6px;
      }
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
`

const LoginLink = styled.h3`
  visibility: visible;
  position: absolute;
  top: -15px;
  right: 15px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 24px;
  line-height: 24px;
  font-family: "Hind Vadodara", sans-serif;
  display: flex;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 16px;
  }
  a {
    color: white;
    display: inline-block;
    :hover {
      text-decoration: none;
    }
  }
  /* i {
    line-height: inherit;
  } */
  .linktext {
    padding-left: 10px;
  }
  .container {
    display: flex;
    align-items: center;
  }
`

const Header = ({ topTitle, topSubtitle, title, subtitle, showHeader }) => (
  <div>
    <header className="site-header">
      <div className="header-sun " data-sr-id="0"></div>
    </header>
    <div className="container load-hidden">
      <div className="site-header-inner-single">
        <div className="brand header-brand sr">
          <h1 className="m-0">
            <a>
              <div className="hero-logo">
                <div
                  className="hero-media "
                  style={{
                    perspective: "800px",
                  }}
                >
                  <img
                    width={400}
                    className="logo"
                    src="/uploads/LivingWisely_Final.jpg"
                  />
                </div>
              </div>
            </a>
          </h1>
        </div>
      </div>
    </div>
    {topTitle && (
      <section className="hero">
        <div className="container">
          <div className="hero-inner">
            <div className="hero-copy">
              <h1 className="hero-title mt-0" id="no-justify">
                {topTitle}
              </h1>
              <p className="hero-paragraph text-lg">{topSubtitle}</p>
            </div>
          </div>
        </div>
      </section>
    )}

    {showHeader === false ? null : (
      <section className="clients section">
        <div className="container">
          <div className="clients-inner">
            <div className="container">
              <Nav className="list-reset mb-0">
                <li className>
                  <Link
                    className="button button-primary button-block cta cta-shadow"
                    to="/individuals"
                  >
                    <i className="fa fa-home" />
                    Home
                  </Link>
                </li>
                <li className>
                  <Link
                    className="button button-primary button-block cta cta-shadow"
                    to="/individuals/howlwworks"
                  >
                    <i
                      style={{ marginRight: "4px" }}
                      className="fa fa-lightbulb"
                    />
                    How LivingWisely Works
                  </Link>
                </li>
                <li className>
                  <Link
                    className="button button-primary button-block cta cta-shadow"
                    to="/individuals/faq"
                  >
                    <i className="fa fa-question-circle" />
                    FAQs & Reviews
                  </Link>
                </li>
                <li className>
                  <Link
                    className="button button-primary button-block cta cta-shadow"
                    to="/individuals/team"
                  >
                    <i className="fa fa-users" />
                    Our Team & News
                  </Link>
                </li>
                <li className>
                  <Link
                    className="button button-primary button-block cta cta-shadow"
                    to="individuals/facilitators"
                  >
                    <i className="fa fa-pen" />
                    Facilitator Training
                  </Link>
                </li>
                <li className>
                  <Link
                    className="button button-primary button-block cta cta-shadow"
                    to="individuals/cart"
                  >
                    <i className="fa fa-bullseye" />
                    Program Shopping Cart
                  </Link>
                </li>
              </Nav>
            </div>
          </div>
        </div>
      </section>
    )}
    {title && <ContentHeader title={title} subtitle={subtitle} />}
    <LoginLink className="button-primary">
      <div className="container">
        <i class="fas fa-sign-in-alt"></i>
        <div className="linktext">
          <a href="https://individuals.livingwisely.org/">
            Program
            <br />
            Login
          </a>
        </div>
      </div>
    </LoginLink>
  </div>
)
export default Header
